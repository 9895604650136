const INITIAL_STATE = {
  collection: [
    {
      id: 1,
      title: "Champions League",
      start: 0,
      end: 8
    },
    {
      id: 2,
      title: "Formula 1",
      start: 8,
      end: 18
    },
    {
      id: 3,
      title: "Australian Open",
      start: 18,
      end: 25
    },
    {
      id: 4,
      title: "NBA",
      start: 25,
      end: 30
    },
    {
      id: 5,
      title: "NFL - Fútbol Americano",
      start: 30,
      end: 34
    },
    {
      id: 6,
      title: "UFC 148",
      start: 34,
      end: 39
    }
  ]
};

const collectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default collectionReducer;
